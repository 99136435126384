@tailwind base;
@tailwind components;
@tailwind utilities;

/* work-sans-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/work-sans-v18-latin-regular.woff2') format('woff2');
}

/* work-sans-italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/work-sans-v18-latin-italic.woff2') format('woff2');
}

/* work-sans-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/work-sans-v18-latin-500.woff2') format('woff2');
}

/* work-sans-600 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/work-sans-v18-latin-600.woff2') format('woff2');
}

/* work-sans-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/work-sans-v18-latin-700.woff2') format('woff2');
}

/* pt-serif-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'PT Serif', sans-serif;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/pt-serif-v18-latin-regular.woff2') format('woff2');
}

/* pt-serif-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'PT Serif', sans-serif;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/pt-serif-v18-latin-700.woff2') format('woff2');
}

@layer base {
    html {
        font-family: 'Work Sans', system-ui, sans-serif;
    }
    h1, h2, h3, h4 {
        font-family: 'PT Serif', sans-serif;
        font-weight: 700;
        line-height: 1.4;
    }
}